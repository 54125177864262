<template>
  <div class="Column">
    <div class="mainBodyA">
      <div class="wow fadeInUp animated">
        <div class="ptit marginT40">
          <img src="../../assets/images/party/icon.png" />
          <span class="ptit_red">党组织基本情况</span>
          <i class="ptit_ftit"
            >THE BASIC INFORMATION ABOUT CHINESE COMMUNIST PARTY</i
          >
        </div>
        <ul class="profile marginT40 clear">
          <li>
            <div class="profileTbg">
              <div class="profile_tit_cn">党组织概况</div>
              <div class="profile_tit_en marginT10">
                PARTY ORGANIZATION OVERVIEW
              </div>
              <div class="profile_p marginT10">
                <p>
                  {{ overviewTxt }}
                </p>
              </div>
              <div class="profile_img marginT10">
                <img src="../../assets/images/party/dhtit.png" />
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- 党组织 -->
      <div class="wow fadeInUp animated">
        <div class="ptit marginT40">
          <img src="../../assets/images/party/icon.png" />
          <span class="ptit_red">党建活动图集</span>
          <i class="ptit_ftit">PARTY BUILDING ACTIVITIES ATLAS</i>
        </div>
        <swiperCpt
          class="swiperhor"
          :autoplay="true"
          :loop="false"
          sid="QualificationSwiper"
          ref="QualificationSwiper"
          v-if="activityList.length > 0"
        >
          <div
            class="swiper-slide"
            v-for="(item, index) in activityList"
            :key="index"
          >
            <ul class="pimg marginT20 clear">
              <li v-for="(child, index) in item.arr" :key="index">
                <div class="pimg_tit" >
                  <img :src="fileUrlHeader + child.imageUrl" :large="fileUrlHeader + child.imageUrl" :preview="child.imageName" :preview-text="child.imageName" />
                </div>
                <div class="pimg_p textSl1">
                  {{ child.imageName }}
                </div>
              </li>
            </ul>
          </div>
        </swiperCpt>
      </div>

      <!-- 廉政建设 -->
      <div class="wow fadeInUp animated">
        <div class="ptit marginT40">
          <img src="../../assets/images/party/icon.png" />
          <span class="ptit_red">廉政建设</span>
          <i class="ptit_ftit">CONSTRUCTION OF A CLEAN GOVERNMENT</i>
        </div>
        <ul class="phonest marginT20 clear">
          <li v-for="(item, index) in lzlist" :key="index">
            <div
              class="nlink"
              @click="
                splicingUrl({
                  base: '/party-details?id=' + item.infoId,
                  index: index - 0 + 1 + (lzPageNo - 1) * PageSize,
                  type: 'lz',
                },item.externallinks)
              "
            >
              <div class="phonest_img">
                <img :src="fileUrlHeader + item.infoImage" />
              </div>
              <div class="phonest_con">
                <div class="phonest_con_tit fontsize18 textSl1">
                  {{ item.infoName }}
                </div>
                <div class="phonest_con_b marginT15 clear">
                  <div class="phonest_con_btime color666 fontsize16 fl">
                    {{ onConversion(item.publishTime) }}
                  </div>
                  <div class="phonest_con_detail colorf3c fontsize16 fr">
                    <span>查看详情</span>
                    <div class="phonest_con_detailbg"></div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div class="pagecon">
          <el-pagination
            background
            layout="prev, pager, next"
            :page-size="PageSize"
            :total="lzTotal"
            @size-change="getlzPage"
            @current-change="getlzPage"
          >
          </el-pagination>
        </div>
      </div>

      <!-- 群团建设 -->
      <div class="wow fadeInUp animated">
        <div class="ptit marginT40">
          <img src="../../assets/images/party/icon.png" />
          <span class="ptit_red">群团建设</span>
          <i class="ptit_ftit">ASSOCIATION CONSTRUCTION</i>
        </div>
        <ul class="phonest marginT20 clear">
          <li v-for="(item, index) in qtlist" :key="index">
            <div
              class="nlink"
              @click="
                splicingUrl({
                  base: '/party-details?id=' + item.infoId,
                  index: index - 0 + 1 + (qtPageNo - 1) * PageSize,
                  type: 'qt',
                },item.externallinks)
              "
            >
              <div class="phonest_img">
                <img :src="fileUrlHeader + item.infoImage" />
              </div>
              <div class="phonest_con">
                <div class="phonest_con_tit fontsize18 textSl1">
                  {{ item.infoName }}
                </div>
                <div class="phonest_con_b marginT15 clear">
                  <div class="phonest_con_btime color666 fontsize16 fl">
                    {{ onConversion(item.publishTime) }}
                  </div>
                  <div class="phonest_con_detail colorf3c fontsize16 fr">
                    <span>查看详情</span>
                    <div class="phonest_con_detailbg"></div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div class="pagecon">
          <el-pagination
            background
            layout="prev, pager, next"
            :page-size="PageSize"
            :total="qtTotal"
            @size-change="getqtPage"
            @current-change="getqtPage"
          >
          </el-pagination>
        </div>
      </div>

      <!-- 友情链接 -->
      <div class="wow fadeInUp animated">
        <div class="ptit marginT40">
          <img src="../../assets/images/party/icon.png" />
          <span class="ptit_red">友情链接</span>
          <i class="ptit_ftit">FRIENDLY LINK </i>
        </div>
        <ul class="rlink marginT20 clear">
          <li v-for="(item, index) in linkList" :key="index">
            <a target="_blank" :href="item.linkUrl">
              <img :src="fileUrlHeader + item.logo" />
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!-- 图片预览 -->
    <!-- <el-dialog
      center
      :title="dialogImgObj.imageName"
      :visible.sync="dialogTableVisible"
    >
      <img
        class="dialogImg"
        :src="$store.state.baseUrl + dialogImgObj.imageUrl"
        :alt="dialogImgObj.imageName"
      />
    </el-dialog> -->
  </div>
</template>

<script>
import swiperCpt from "@/components/common/swiperCpt.vue";
export default {
  components: { swiperCpt },
  data() {
    return {
      // dialogTableVisible: false,
      // dialogImgObj: {},
      lzlist: [],
      qtlist: [],
      linkList: [], //友情链接
      activityList: [], //党建活动图集
      overviewTxt: "", //党组织概况
      qtTotal: 1, // 群团总数
      lzTotal: 1, // 廉政总数
      qtPageNo: 1, // 群团当前页
      lzPageNo: 1, // 廉政当前页
      PageSize: 3, // 每页显示条数
      fileUrlHeader: this.$store.state.fileUrlHeader,
    };
  },
  mounted() {
    //廉政、群团
    this.getConstructionlz();
    this.getConstructionqt();
    //获取详情
    this.getpartyDetail();
    new this.$wow.WOW({
      live: false,
      offset: 0,
    }).init();
  },
  methods: {
    // // 图片预览
    // OnChangeDialogImgObj(item) {
    // //   console.log(item);
    //   // 初始化
    //   this.dialogImgObj = {};
    //   this.dialogImgObj = item;
    //   this.$nextTick(() => {
    //     this.dialogTableVisible = true;
    //   });
    // },
    // 时间转换
    onConversion(date) {
      // console.log(date);
      var nDate = new Date(date.replace(new RegExp(/-/gm), "/"));
      // let nDate = new Date(date),
      var y = nDate.getFullYear(),
        m = ("0" + (nDate.getMonth() + 1)).slice(-2),
        d = ("0" + nDate.getDate()).slice(-2);
      // console.log(date)
      return y + "-" + m + "-" + d;
    },
     // url拼接
     splicingUrl({ base, index, type },externallinks) {
        if(externallinks){
            window.open(externallinks)
        }else{
            this.$router.push({ path: base + "&index=" + index + "&type=" + type });
        }
        // return base + "&index=" + index + "&type=" + type;
    },
    //廉政
    async getlzPage(page) {
      // 获取第 page 页数据
      // console.log(page);
      this.lzPageNo = page;
      this.getConstructionlz();
    },
    async getqtPage(page) {
      // 获取第 page 页数据
      // console.log(page);
      this.qtPageNo = page;
      this.getConstructionqt();
    },
    //廉政建设
    async getConstructionlz() {
      let res = await this.$request.getConstructionType({
        newsType: 2,
        pageNo: this.lzPageNo,
        pageSize: this.PageSize,
      });
      this.lzlist = res.newsInfo.list;
      // console.log(this.lzlist);
      this.lzTotal = res.newsInfo.total;
    },
    //群团建设
    async getConstructionqt() {
      let res = await this.$request.getConstructionType({
        newsType: 3,
        pageNo: this.qtPageNo,
        pageSize: this.PageSize,
      });
      this.qtlist = res.newsInfo.list;
      // console.log(this.qtlist);
      this.qtTotal = res.newsInfo.total;
    },
    //详情
    async getpartyDetail() {
      let res = await this.$request.getConstruction({
        imageColumnId: 46,
        enterpriseId: "",
      });
      this.activityList = this.getpic(3, res.partyImages);
      // console.log("三组", this.activityList);
      this.overviewTxt = res.partySituation;
      this.linkList = res.partyFriendlyLinks;
    },
    //图片处理
    getpic(numD, data) {
      let i = Math.ceil(data.length / numD);
      let list = [];
      for (let j = 0; j < i; j++) {
        let arr = [];
        data.map((val, index) => {
          let num = Math.ceil((index + 1) / numD);
          if (j + 1 == num) {
            arr.push(val);
            list[j] = {
              arr: arr,
            };
          }
        });
      }
      return list;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialogImg {
  min-width: 546px;
}
.pimg li {
  &:hover .pimg_p {
    background-color: #f3ca00;
    color: #fff;
  }
  .pimg_p{
    min-height: 20px;
  }
  .pimg_tit {
    width: 19.7917vw;
    height: 13.3333vw;
    overflow: hidden;

    &:hover img {
      transform: scale(1.06, 1.06);
      transition: 0.6s;
    }
  }
}

.phonest li {
  &:hover .phonest_con {
    background-color: #f3ca00;
    color: #fff !important;
  }

  &:hover .phonest_con_btime {
    color: #fff !important;
  }

  &:hover .phonest_con_btime::before {
    background-color: #fff !important;
  }

  &:hover .phonest_con_detail {
    color: #fff !important;
  }

  &:hover img {
    transform: scale(1.06, 1.06);
    transition: 0.6s;
  }
  .phonest_con_tit{
    height: 20px;
  }
}

.pagecon {
  text-align: center;
  padding: 1.0417vw 0;
}

.pagecon /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #f3ca00;
  color: #fff;
}

.pagecon /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #f3ca00;
}

@media (min-width: 1920px) {
  .pimg li {
    &:hover .pimg_p {
      background-color: #f3ca00;
      color: #fff;
    }

    .pimg_tit {
      width: 380px;
      height: 256px;
      overflow: hidden;

      &:hover img {
        transform: scale(1.06, 1.06);
        transition: 0.6s;
      }
    }
  }

  .phonest li {
    &:hover .phonest_con {
      background-color: #f3ca00;
      color: #fff !important;
    }

    &:hover .phonest_con_btime {
      color: #fff !important;
    }

    &:hover .phonest_con_btime::before {
      background-color: #fff !important;
    }

    &:hover .phonest_con_detail {
      color: #fff !important;
    }

    &:hover img {
      transform: scale(1.06, 1.06);
      transition: 0.6s;
    }
  }

  .pagecon {
    text-align: center;
    padding: 20px 0;
  }
}
</style>
