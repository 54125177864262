<template>
    <div>
        <div class="mainBodyA">
            <ul class="phonest marginT40  clear">
                <li v-for="(item, index) in infomanges" :key="index">
                    <div class="nlink" @click="
                            splicingUrl({
                                base: '/party-details?id=' + item.infoId,
                                index:
                                    index -
                                    0 +
                                    1 +
                                    (impoptantPageNo - 1) * impoptantPageSize,
                                type: 'company'
                            },item.externallinks)
                        ">
                        <div class="phonest_img">
                            <img :src="fileUrlHeader + item.infoImage" />
                        </div>
                        <div class="phonest_con">
                            <div class="phonest_con_tit fontsize18 textSl1">
                                {{ item.infoName }}
                            </div>
                            <div class="phonest_con_b marginT15 clear">
                                <div class="phonest_con_btime color666 fontsize16 fl">
                                    {{ onConversion(item.publishTime) }}
                                </div>
                                <div class="phonest_con_detail colorf3c fontsize16 fr">
                                    <span>查看详情</span>
                                    <div class="phonest_con_detailbg"></div>
                                    <!-- <img
                                        src="../../assets/images/party/line1.png"
                                    /> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="pagecon">
                <el-pagination background layout="prev, pager, next" :page-size="impoptantPageSize"
                    :total="impoptantTotal" @size-change="getPartyNews" @current-change="getPartyNews">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                infomanges: [],
                impoptantTotal: 1, // 总数
                impoptantPageNo: 1, // 当前页
                impoptantPageSize: 6, // 每页显示条数
                fileUrlHeader: this.$store.state.fileUrlHeader
            };
        },
        mounted() {
            this.getNewList2();
        },
        methods: {
            // 时间转换
        onConversion(date) {
            // console.log(date);
            var nDate = new Date(date.replace(new RegExp(/-/gm), "/"));
            // let nDate = new Date(date),
            var y = nDate.getFullYear(),
                m = ("0" + (nDate.getMonth() + 1)).slice(-2),
                d = ("0" + nDate.getDate()).slice(-2);
            // console.log(date)
            return y + "-" + m + "-" + d;
        },
            // url拼接
            splicingUrl({ base, index, type },externallinks) {
                if(externallinks){
                    window.open(externallinks)
                }else{
                    this.$router.push({ path: base + "&index=" + index + "&type=" + type });
                }
                // return base + "&index=" + index + "&type=" + type;
            },
            async getPartyNews(page) {
                this.impoptantPageNo = page;
                this.getNewList2();
            },
            async getNewList2() {
                let res = await this.$request.getPartyNews({
                    pageNo: this.impoptantPageNo,
                    pageSize: this.impoptantPageSize
                });
                this.infomanges = res.partyNewsInfo.list;
                // console.log(this.infomanges);
                this.impoptantTotal = res.partyNewsInfo.total;
            }
        }
    };
</script>

<style lang="scss" scoped>
    .mainBodyA {
        margin-bottom: 1.0417vw;
    }

    .phonest li {
        margin-top: 1.5625vw;

        &:hover .phonest_con {
            background-color: #f3ca00;
            color: #fff !important;
        }

        &:hover .phonest_con_btime {
            color: #fff !important;
        }

        &:hover .phonest_con_btime::before {
            background-color: #fff !important;
        }

        &:hover .phonest_con_detail {
            color: #fff !important;
        }

        &:hover img {
            transform: scale(1.06, 1.06);
            transition: 0.6s;
        }
        .phonest_con_tit{
            height: 20px;
        }
    }

    @media (min-width: 1920px) {
        .mainBodyA {
            margin-bottom: 20px;
        }

        .phonest li {
            margin-top: 30px;

            &:hover .phonest_con {
                background-color: #f3ca00;
                color: #fff !important;
            }

            &:hover .phonest_con_btime {
                color: #fff !important;
            }

            &:hover .phonest_con_btime::before {
                background-color: #fff !important;
            }

            &:hover .phonest_con_detail {
                color: #fff !important;
            }

            &:hover img {
                transform: scale(1.06, 1.06);
                transition: 0.6s;
            }
        }

    }


    .pagecon {
        text-align: center;
        padding: 20px 0;
    }

    .pagecon /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #f3ca00;
        color: #fff;
    }

    .pagecon /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
        color: #f3ca00;
    }
</style>