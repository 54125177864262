<template>
  <div class="contain">
    <Banner
      :banner_img="resBanners"
      banner_en="THE PARTY BUILDING"
      banner_cn="党的建设"
    ></Banner>
    <div class="pnav clear">
      <div
        :class="[item.key === componentTag.key ? 'on' : '']"
        @click="OnChangeCont(item)"
        v-for="item in pnavList"
        :key="item.key"
      >
        <router-link class="nuxtLink" :to="'/party?key=' + item.key">
          {{ item.text }}
        </router-link>
      </div>
    </div>

    <!-- 核心模块 -->
    <component :is="componentTag.key"></component>
  </div>
</template>
<script>
import Column from "@/components/party/Column";
import News from "@/components/party/News";
import Banner from "@/components/common/Banner.vue";

export default {
  data() {
    return {
      resBanners: [],
      componentTag: {
        index: 0,
        text: "党建专栏",
        key: "Column",
      },
      pnavList: [
        {
          index: 0,
          text: "党建专栏",
          key: "Column",
        },
        {
          index: 1,
          text: "党建新闻",
          key: "News",
        },
      ],
    };
  },
  mounted() {
    this.getBanners();
  },
  methods: {
    OnChangeCont(data) {
      this.componentTag = data;
    },
    // 获取banner
    async getBanners() {
      let res = await this.$request.getImageAdvertisingList({
        advertisingPosition: "4",
      });
      if (res.code === 200) {
        this.resBanners = [];
        this.resBanners = res.rows;
      }
    },
  },
  watch: {
    // 监听路由发生改变
    $route: {
      handler(newVal, oldval) {
        if (newVal != oldval && newVal.query.key) {
          let resList = this.pnavList.filter((item) => {
            // console.log(newVal.query.key);
            // console.log(item.key);
            return newVal.query.key === item.key;
          });
          this.componentTag = resList[0];
        }
      },
      immediate: true,
    },
  },
  components: {
    Column,
    Banner,
    News,
  },
};
</script>
<style>
@import "../../assets/css/party.css";
</style>
